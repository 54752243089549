/** @format */

import config from '../../config';
import routes from '../../routes';
import { actions } from '../../reducers/app';

import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';

import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';

import { useHistory } from 'react-router-dom';
import { SettingsContext } from '../../components/shared/SettingsContext';
import SportSelector from '../trade/SportSelector';

import NavMenuItem from './NavMenuItem';
import NavSubMenu from './NavSubMenu';
import MoneyHolder from './MoneyHolder';
import { DepositLink } from './DepositLink';
import MarketSelector from './market-selector/MarketSelector';
import { IconButton } from '@mollybet/ui';
import { mdiMagnify } from '@mdi/js';
import ExtraNavButtons from './ExtraNavButtons.js';

import SearchBox from '../search/search-box/SearchBox';

import styled, { useTheme, withTheme } from 'styled-components';

import { Paper, Icon } from '../../components/interface';

import { mdiDotsVertical } from '@mdi/js';

import { getHostName, hostname, requestBackend, isProduction} from '../../lib/getToolsUrl';


const NarrowSearchBoxContainer = styled.div`
  display: ${({ searchBarIsFocused }) => (searchBarIsFocused ? 'inline-block' : 'none')};

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    display: none;
  }
`;

const DepositLinkContainer = styled.div`
  height: 41px;
  box-sizing: border-box;

  &:hover {
    background: radial-gradient(#060606, #1e1e1e);
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    height: 30px;
  }
`;

const NavMenuComp = styled(Paper)`
  height: ${config.nav.styles.wide.height};
  min-height: ${config.nav.styles.wide.height};
  max-height: ${config.nav.styles.wide.height};
  z-index: 10;
  overflow: visible;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  background-color: ${({ theme, hostname }) => theme.colours[hostname]};

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    height: ${config.nav.styles.narrow.height};
    min-height: ${config.nav.styles.narrow.height};
    max-height: ${config.nav.styles.narrow.height};
  }
`;

const NavMenuItemHolder = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  white-space: nowrap;
  background-color: ${({ theme, hostname }) => theme.colours[hostname]};

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    width: 6em;
    display: ${({ hide, toggled }) => (hide ? 'none' : toggled ? 'flex' : 'none')};
    flex-direction: column;
    z-index: 7;
    position: absolute;
    top: ${config.nav.styles.narrow.height};
    left: 0px;
  }
`;

const LogoContainerElement = styled.div`
  height: ${config.nav.styles.wide.height};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 14em;
  cursor: pointer;

  img {
    height: 36px;
    max-width: 160px;
  }

  svg {
    display: none;
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    width: 5em;
    height: ${config.nav.styles.narrow.height};

    img {
      height: 28px;
      content: ${({ theme }) =>
        `url(${routes.basename}${!Whitelabel.logos ? '' : Whitelabel.logos.coin[theme.palette]})`};
    }

    svg {
      display: block;
    }
  }
`;

const IconButtonElement = styled(IconButton)`
  height: 41px;
  margin-right: 2em;
  box-sizing: border-box;

  &:hover {
    background: radial-gradient(#060606, #1E1E1E);
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    height: 30px;
    //height: ${config.nav.styles.narrow.height};
  }
`;

const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const MarketSelectorContainerElement = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  background-color: ${({ theme, hostname }) => theme.colours[hostname]};

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    display: none;
  }
`;

const SportSelectorContainerElement = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'block')};

  @media only screen and (min-width: ${config.narrowBreakpointNav}px) {
    display: none;
  }
`;

const HostInfoItem = styled.div`

  &:first-letter {
    text-transform: capitalize;
  }
  
  margin: 0 1rem 0 3rem;
  font-weight: bold;
  font-size: 16px;
  background: #8B0000;
  padding: 0.5rem;
`

/**
 * This component is a generic top bar navigation element.
 * It looks at the structure in the `./routes.js` file and determines how it should compose the menus and items.
 */

const NavMenuLogoDisplay = ({ toggle }) => {
  const theme = useTheme();
  const history = useHistory();
  const { navLayout } = useContext(SettingsContext);
  const homeHelper = (event) => {
    toggle();
    if ({ navLayout } !== 'narrow') {
      // Middle button click
      if (event?.button === 1) window.open(routes.default, '_blank');
      else history.push(routes.default);
    }
  };
  return (
    <LogoContainerElement onMouseUp={homeHelper}>
      <Icon path={mdiDotsVertical} width={24} height={24} />
      <img
        alt="logo"
        height="36"
        className="nav-menu-logo"
        src={`${routes.basename}${!Whitelabel.logos ? '' : Whitelabel.logos.wide[theme.palette]}`}
      />
    </LogoContainerElement>
  );
};

class NavMenu extends React.PureComponent {
  static contextType = SettingsContext;

  state = {
    toggled: false,
  };

  logout = () => {
    this.props.actions.logout();
  };

  toggle = () => {
    this.props.actions.unfocusSearchBar();
    if (this.context.navLayout === 'narrow') {
      this.setState({ toggled: !this.state.toggled });
    }
  };

  //check if 'onlyShowIf' confition is in the user profile config and is true
  //we probaly want to make this more generic
  onlyShowIf = (it) => {
    if (typeof it.onlyShowIf === 'undefined') {
      return true;
    } else {
      let stack = it.onlyShowIf.split(' ');
      let res = null;

      for (let i = 0; i < stack.length; i++) {
        let flip = stack[i].startsWith('!');
        let condition = stack[i].replace(/^!/, '');
        let ret = false;

        if (condition === 'narrow') {
          ret = this.context.contentLayout === 'narrow' || this.context.navLayout === 'narrow';
        } else if (condition === 'normal') {
          ret = this.context.contentLayout === 'normal';
        } else if (condition === 'sudoer') {
          ret = !!this.props.sudoer;
        } else if (condition === 'mobilePlatformRedirect') {
          ret = !!Whitelabel.mobilePlatformLink;
        } else if (this.props.switches && this.props.switches.has(condition)) {
          ret = this.props.switches.get(condition);
        } else if (this.props.permissions) {
          ret = this.props.permissions.getIn(condition.split('.'), false);
        }
        ret = flip ? !ret : ret;

        if (res === null) {
          res = ret;
        } else if (stack[i - 1] === '||') {
          res = res || ret;
        } else if (stack[i - 1] === '&&') {
          res = res && ret;
        }
      }

      return res;
    }
  };

  removeSearchFocus = () => {
    this.props.actions.unfocusSearchBar();
  };

  requestToken = (url) => () => {
    this.props.actions.requestToken({
      actions: this.props.actions,
      url,
      lang: this.context.language,
    });
  };

  /**
   * This method renders the component via React
   */
  render() {
    let termsDict = {};
    if (Whitelabel.term) {
      termsDict = Whitelabel.terms[this.props.groupId] || Whitelabel.terms['all'];
    }

    const selectSearchHelper = () => {
      this.props.actions.focusSearchBar();
    };

    let allTermsAccepted = true;
    //don't check for sudoers
    if (!this.props.sudoer) {
      for (let term in termsDict) {
        let isAccepted = false;

        let when = 0;
        if (this.props.terms) {
          when = this.props.terms.getIn([term], 0);
        }
        when = new Date(when);
        let lastUpdated = new Date(termsDict[term].lastUpdated);

        if (when > lastUpdated) {
          isAccepted = true;
        }
        if (isAccepted === false) {
          allTermsAccepted = false;
        }
      }
    }

    return (
      <NavMenuComp
        elevation={this.props.theme.palette === 'dark' ? 0 : 2}
        noBr="true"
        noShadow="true"
        hostname={getHostName()}
      >
        <FlexContainer>
          <NavMenuLogoDisplay toggle={this.toggle} />
          <MarketSelectorContainerElement
            hide={
              this.props.searchBarFocusState === 'focused' ||
              !this.props.canSeeTrade ||
              !allTermsAccepted
            }
            hostname={getHostName()}
          >
            <MarketSelector />
          </MarketSelectorContainerElement>
          <SportSelectorContainerElement
            hide={
              this.props.searchBarFocusState === 'focused' ||
              !this.props.canSeeTrade ||
              !allTermsAccepted
            }
          >
            <SportSelector />
          </SportSelectorContainerElement>
          <NavMenuItemHolder
            hide={!allTermsAccepted || this.props.searchBarFocusState === 'focused'}
            toggled={this.state.toggled}
          >
            {routes.items
              ? routes.items.map((it, index) => {
                  if (it.menu && this.onlyShowIf(it)) {
                    if (it.items) {
                      return <NavSubMenu key={index} {...it} />;
                    } else {
                      return <NavMenuItem hostname={getHostName()} key={index} {...it} />;
                    }
                  } else {
                    return null;
                  }
                })
              : []}
          </NavMenuItemHolder>
          <NavMenuItemHolder
            hide={this.context.navLayout === 'narrow' || !allTermsAccepted || this.props.searchBarFocusState === 'focused' || this.context.featureSonic !== true }
          >
            <NavMenuItem hostname={getHostName()} url={Whitelabel.sonicUrl} title={{defaultMessage: `New Platform`, id: 'trade.whitelabel.alpha'}} className={'beta-button'}/>
            </NavMenuItemHolder>
            {!isProduction()?  <>
                <HostInfoItem>{`You're on ${hostname} / Backend: ${requestBackend}`}</HostInfoItem>
              </>
            : null
            }
        </FlexContainer>
        <NarrowSearchBoxContainer searchBarIsFocused={this.props.searchBarFocusState === 'focused'}>
          <SearchBox stateSearchBarFocusState={this.props.searchBarFocusState} />
        </NarrowSearchBoxContainer>
        <FlexContainer>
          {this.props.searchBarFocusState === 'focused' ? null : <ExtraNavButtons />}
          {this.props.searchBarFocusState === 'focused' ? null : (
            <IconButtonElement onClick={selectSearchHelper}>
              <Icon
                width={22}
                height={22}
                path={mdiMagnify}
                color={this.props.theme.colours.white}
              />
            </IconButtonElement>
          )}
          <DepositLinkContainer>
            <DepositLink
              props={this.props}
              context={this.context}
              requestToken={this.requestToken}
            />
          </DepositLinkContainer>
          <MoneyHolder />
        </FlexContainer>
      </NavMenuComp>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// turn state of combined reducers into state required by component
const mapStateToProps = (state) => ({
  sudoer: state.getIn(['base', 'profile', 'sudoer'], ''),
  permissions: state.getIn(['base', 'profile', 'config'], null),
  switches: state.getIn(['base', 'switches'], null),
  canSeeTrade: state.getIn(['base', 'profile', 'config', 'canSeeTrade'], true),
  terms: state.getIn(['base', 'settings', 'terms'], null),
  groupId: state.getIn(['base', 'profile', 'groupId'], 'all'),
  searchBarFocusState: state.getIn(['ui', 'searchBarFocusState'], 'unfocused'),
  externalURLsToken: state.getIn(['base', 'profile', 'externalURLsToken'], ''),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(injectIntl(NavMenu)));
