/** @format */

import DataStream from '@mollybet/frontend-common/dist/lib/DataStream';

export type HostnameType = 'skaffold' | 'prod' | 'localhost' | 'sandbox' | 'doge';

export const hostname = window.location.origin.includes('.skaffold')
? 'skaffold'
: window.location.origin.includes('.localhost')
? 'localhost'
: window.location.origin.includes('shared-sandbox')
? 'sandbox'
: window.location.origin.includes('doge.devmolly')
? 'doge'
: 'prod';

export const getHostName = (): HostnameType => hostname;

export const isSandbox = (): boolean => window.location.host.includes('shared-sandbox.');
export const isSkaffold = (): boolean => window.location.host.includes('.skaffold');
export const isLocalhost = (): boolean => window.location.host.includes('localhost');
export const isDoge = (): boolean =>  window.location.host.includes('doge.devmolly');
export const isProduction = (): boolean => !isSandbox() && !isSkaffold() && !isLocalhost() && !isDoge();

export const isSandboxRedirect = DataStream.getHostRedirect()?.includes('shared-sandbox');
export const isProductionRedirect = DataStream.getHostRedirect()?.includes('mollybet');

export const requestBackend = isSandboxRedirect? 'sandbox' : isProductionRedirect ? 'production' : 'development';

export const getToolsUrl = (
  protocol: string = 'https',
  defaultValue: string = 'tools.devmolly.com'
): string => {
  if (isSandbox()) return `${protocol}://tools.shared-sandbox.devmolly.com`;
  if (isSkaffold()) {
    return 'http://tools.skaffold';
    // const hostRedirect = DataStream.getHostRedirect();
    // const pathRedirect = hostRedirect && hostRedirect.split('://')[1];
    // return `${protocol}://${pathRedirect || 'tools.skaffold'}`;
  }
  return `${protocol}://${defaultValue}`;
};
