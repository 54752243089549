/** @format */

import { List, Map } from 'immutable';
import React from 'react';
import { ThemeType } from '../../themes/types';

export interface SettingsContextType {
  [key: string]: any;
  xrates: Map<string, number>;
  language: string;
  theme: string;
  timezone: string;
  isAuth: string;
  canSeeBrokerage: boolean;
  apiSync: boolean;
  pricesSync: boolean;
  brokerSync: boolean;
  placer: string;
  currentUser: string;
  sessionId: string;
  name: string;
  agent: boolean;
  masterAgent: boolean;
  subAgent: boolean;
  superuser: boolean;
  seePt: boolean;
  limitedAdmin: boolean;
  netPrices: boolean;
  exchangeMode: string;
  hideCredit: boolean;
  hideUsername: boolean;
  priceType: string;
  contentLayout: string;
  navLayout: string;
  navMenuHeight: number;
  fontSize: number;
  userTradeView: string;
  userTradeViewOrNarrow: string;
  interfaceWidth: number;
  interfaceHeight: number;
  ccyCode: string;
  displayCcy: string;
  spyOnGroup: boolean;
  dockedDraggables: boolean;
  betslipTint: boolean;
  sport: string;
  baseSport: string;
  enabledGroups: Map<string, Map<string, boolean>>;
  hideExchangeOnlyLines: boolean;
  showAgentsInHistory: boolean;
  seeAccounts: boolean;
  orderLogCategories: List<string>;
  tradeTint: boolean;
  hideFavsCompetitionsHeaders: boolean;
  sideOpen: boolean;
  equivalentBets: boolean;
  bookieMinBalances: Map<string, number>;
  multipleAccounts: boolean;
  waitForPmms: number;
  parlayMode: boolean;
  parlaySportRestrictions: unknown;
  maxBetslips: number;
  multipleHandicapLines: boolean;
  autoAddBetsToFavs: boolean;
  allTermsAccepted: boolean;
  advancedOrderPlacementOptions: boolean;
  reselectAccounts: 'always';
  betslipDefaultTimeout: number;
  defaultPrice: number;
  defaultStake: number;
  webPlaceBets: boolean;
  maxOrder: number;
  minOrder: number;
  confirmBet: boolean;
  autofillMiddleHandicaps: boolean;
  extraEdgeHandicaps: boolean;
  alwaysExpandAllMarkets: boolean;
  showOverround: boolean;
  openAnySelection: boolean;
  showAllScores: boolean;
  validTradeBookies: List<string>;
  validPricesBookies: List<string>;
  advancedBetslipInfo: boolean;
  betslipAgent: boolean;
  bookieTint: boolean;
  useSuggested: boolean;
  showBrokerInfoText: boolean;
  valuesInPositionButton: boolean;
  pricesBookies: List<string>;
  disabledBookies: List<string>;
  sortEventsBy: string;
  canCreateUsers: boolean;
  canUseCasino: boolean;
  casinoUser: boolean;
  canCreateTransfers: boolean;
  canUseMultipleAccounts: boolean;
  canChangeCreditLimits: boolean;
  canChangeNetPrices: boolean;
  agentPositionGrids: boolean;
  featureShowInternalInfo: boolean;
  featureAdvancedTradeSettings: boolean;
  featureNetPositionGrids: boolean;
  featureNoBrokerageRestrictions: boolean;
  featureOverround: boolean;
  featureDepositButton: boolean;
  featureLognoice: boolean;
  featureEquivalentBetsOption: boolean;
  featureFlatten: boolean;
  featureTranslatedBetTypes: boolean;
  featureParlay: boolean;
  featureForex: boolean;
  actions: Record<string, unknown>;
  isDemo: boolean;
  _theme: ThemeType;
  isAgentsMollybet: boolean;
}

export const SettingsContext = React.createContext<SettingsContextType>({} as SettingsContextType);
