/** @format */

import config from '../../config';
import { actions } from '../../reducers/app';
import routes from '../../routes';

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';

import { Link } from 'react-router-dom';
import { SettingsContext } from '../../components/shared/SettingsContext';
import styled, { withTheme } from 'styled-components';

import { Paper } from '../../components/interface';
import { ConditionalDemoWrapper } from '../../components/demo';

export const NavMenuItemComp = styled(Paper)`
  width: 8em;
  padding-left: 2px;
  padding-right: 2px;
  line-height: 4em;
  height: 4em;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  user-select: none;
  box-shadow: none !important;
  display: inline-block;
  vertical-align: top;
  overflow: visible;

  background-color: ${({ theme, hostname }) => theme.colours[hostname]};

  .link-title {
    height: 100%;
    text-transform: capitalize;
  }

  a,
  .fake-link {
    width: 100%;
    color: ${(props) => props.theme.paper.fontColor[0]};
  }

  &.unsudo-menu-button {
    background-color: ${(props) => props.theme.warning};

    a,
    .fake-link {
      color: ${(props) => props.theme.colours.black};
    }
  }

  &.beta-button {
    background-color: #7781e9;

    div {
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.4px;
      white-space: normal;
      line-height: 1.125rem;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  .link-title {
    border-bottom: 0.25em solid;
    box-sizing: border-box;

    &:hover {
      border-color: ${(props) => props.theme.colours.white} !important;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.useMobile-menu-button {
    .fake-link {
      background: ${(props) => props.theme.primary} !important;
    }

    .link-title {
      background: ${(props) => props.theme.primary} !important;
      height: 100%;
      text-transform: capitalize;
    }
  }

  &.useAlpha-menu-button {
      background:  #7781e9 !important;
    }
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    min-width: 6em;
    width: auto;
    height: 3em;
    line-height: 3em;
    padding: 0;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    &.active {
      .link-title {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
`;

/**
 * This component is a navigation button that wraps react-router's Link component.
 * It requires at least a title and optionally a route and/or an onClick
 */

class NavMenuItem extends React.Component {
  static contextType = SettingsContext;

  //determine if a menu item is highlighted
  isRouteSelected = () => {
    if (this.props.route) {
      if (
        !this.props.route.includes('trade') &&
        this.props.route.includes('event') &&
        window.location.href.includes('event')
      ) {
        document.title = `${this.props.intl.formatMessage(this.props.title)} - ${
          Whitelabel.name || '?'
        }`;
        return true;
      }
      let atom = this.props.route.split('/').pop();
      let locs = window.location.pathname.split('/');
      //we are trying to see if the route that the item corresponds to
      //is anywhere in the breadcumbs of the routing; there are probably edge cases where this fails
      if (document && locs.indexOf(atom) !== -1) {
        document.title = `${this.props.intl.formatMessage(this.props.title)} - ${
          Whitelabel.name || '?'
        }`;
      }
      // to get around edge case
      if (
        this.props.route.includes('activePositions') &&
        window.location.href.includes('activePositions')
      ) {
        document.title = `${this.props.intl.formatMessage(this.props.title)} - ${
          Whitelabel.name || '?'
        }`;
        return true;
      }
      if (this.props.route.includes('trade') && window.location.href.includes('trade')) {
        return true;
      }
      return locs.indexOf(atom) !== -1;
    } else {
      return false;
    }
  };

  triggerAction = () => {
    if (this.props.triggerAction) {
      this.props.actions[this.props.triggerAction](this.props.triggerData);
    }
  };

  render() {
    let name = (
      <div
        style={{
          borderColor:
            this.isRouteSelected() && !this.props.noActive
              ? this.props.theme.primary
              : 'transparent',
        }}
        className="link-title"
      >
        {this.props.intl.formatMessage(this.props.title)}
      </div>
    );
    return (
      <NavMenuItemComp
        noBr="true"
        noShadow="true"
        elevation={this.props.theme.palette === 'dark' ? 0 : 2}
        className={`${
          this.props.title.id ? this.props.title.id.split('.').pop() + '-menu-button' : ''
        } ${this.isRouteSelected() && !this.props.noActive ? ' active ' : ''}${
          this.props.isSudo ? ' sudo' : ''
        }${this.props.className ? ' ' + this.props.className : ''}`}
        hostname={this.props.hostname}
      >
        <ConditionalDemoWrapper wrap={!this.props.demo}>
          {this.props.route ? (
            <Link to={`${this.props.url || this.props.route}${this.props.hash}`}>{name}</Link>
          ) : this.props.url ? (
            <a
              href={`${this.props.url.indexOf('//') === -1 ? routes.basename : ''}${
                this.props.url
              }${this.props.hash}`}
              onClick={this.triggerAction}
              rel="noopener noreferrer"
              className={`${this.props.className ? ' ' + this.props.className : ''}`}
            >
              {name}
            </a>
          ) : (
            <span className="fake-link" onClick={this.triggerAction}>
              {name}
            </span>
          )}
        </ConditionalDemoWrapper>
      </NavMenuItemComp>
    );
  }
}

//the only acceptable use of bind all actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// turn state of combined reducers into state required by component
const mapStateToProps = (state, ownProps) => {
  let agent = state.getIn(['base', 'profile', 'config', 'agent'], false);
  let hash = state.getIn(ownProps.hash || [], '');
  if (typeof hash !== 'string' || !hash || agent) {
    hash = ownProps.hashFallback || '';

    if (agent && hash) {
      let displayCcy = state
        .getIn(
          ['base', 'settings', 'general', 'displayCcy'],
          state.getIn(['base', 'profile', 'ccyCode'], '')
        )
        .toLowerCase();
      hash += '&ccyCode=' + displayCcy;
    }
  }

  return {
    hash,
    routing: state.get('routing', null),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(injectIntl(NavMenuItem)));
