/** @format */

import './App.sass';
import config from './config';
import routes from './routes';
import sass from './base';
import themes, { sizes } from './themes';

import React, { useContext } from 'react';

import merge from 'lodash/merge';
import debounce from 'lodash/debounce';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from './reducers/app';
import pkg from '../package.json';

import FourOughFour from './views/FourOughFour';
import NavMenu from './components/nav/NavMenu';
import AgentNavMenu from './components/nav/AgentNavMenu';
import Deposit from './components/shared/Deposit';
import { SettingsContext } from './components/shared/SettingsContext';
import SoundManager from './components/SoundManager';
import PlatformHealthRecorder from './components/PlatformHealthRecorder';
import BetTypeOptions from './components/trade/bet-type-options/BetTypeOptions';
import PayoutCalc from './components/PayoutCalc';
import FeedbackOverlay from './components/feedback/FeedbackDialog';
import Announcements from './components/Announcements';
import { extractPaths } from './lib/nav';
import { toCamelCaseAll } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { betslipStress } from '@mollybet/frontend-common/dist/lib/trade';
import Telemetry from '@mollybet/frontend-common/dist/lib/Telemetry';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import SearchResultsContainer from './components/search/search-results-container/SearchResultsContainer';
import { Timings } from './timing';
import { Button } from './components/interface';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';


/*eslint-disable*/
//basically we need to sneak in the translations used by this.props.intl.formattedMessage
//react-intl babel plugin will not pick these up :(
import Tooltips from './translations/Tooltips';
import Nav from './translations/Nav';
import Common from './translations/Common';
/*eslint-enable*/

//Internationalization
import { IntlProvider } from 'react-intl';
//this contains all the languages
import * as messages from './translations';
import Loading from './components/shared/Loading';

//Theming
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { createTheme } from '@mollybet/ui';
import ViewErrorBoundary from './components/error-boundary/ViewErrorBoundary';
import ComponentErrorBoundary from './components/error-boundary/ComponentErrorBoundary';

/**
 * This is the core application singleton instantiated in `index.js`.
 * Apart from authentication logic, you probably don't want to touch this.
 */

const FontStyles = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.fonts.base};

    h1, h2, h3, h4, h5, h6 {
      font-family: ${(props) => props.theme.fonts.headings};
    }

    h1 {
      font-size: 24px;
      font-weight: 500;
    }
  }
`;

const LogoutButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  padding: 8px 16px;
  background-color: black;
`;

const PrivateRoute = ({ component: Component, logout, ...rest }) => {
  const { isAgentsMollybet } = useContext(SettingsContext);

  if (window.location.pathname !== window.Timings.path) {
    window.Timings.handlePageChange();
    window.Timings.path = window.location.pathname;
    window.Timings.pageEnd = +new Date();
  }

  return (
      <Route
        {...rest}
        render={(props) =>
          rest.isAuth === 'yes' ? (
            <div className="app-view-container">
              {isAgentsMollybet ? 
               <AgentNavMenu /> : <NavMenu /> }
              <React.Suspense fallback={<Loading />}>
                <Component {...props} />
              </React.Suspense>
            </div>
          ) : rest.isAuth === 'no' ? (
            <Redirect to={`${routes.notAuthenticated}?next=${props.location.pathname}`} />
          ) : (
            <Loading />
          )
        }
      />
    )
};

const PublicRoute = ({ component: Component, ...rest }) => {
  if (window.location.pathname !== window.Timings.path) {
    window.Timings.handlePageChange();
    window.Timings.path = window.location.pathname;
    window.Timings.pageEnd = +new Date();
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <React.Suspense fallback={<Loading />}>
          <Component {...props} />
        </React.Suspense>
      )}
    />
  );
};
class App extends React.Component {
  constructor(props) {
    super(props);

    if (config.support.tools.actionHack) {
      window._callAction = (action, data) => {
        this.props.actions[action](toCamelCaseAll(data));
      };
    }

    let toSet = {};
    let toSet2 = {};
    const _theme = createMuiTheme(merge(toSet, sass.themeBase, config.themes[this.props.theme]));
    const _molly_theme = createTheme(
      merge(toSet2, themes[this.props.theme], {
        primary: config.themes[this.props.theme].palette.common.accent,
      }),
    );

    this.state = {
      theme: this.props.theme,
      _theme,
      _molly_theme,
      _statsInterval: null,
      _pingInterval: setInterval(
        this.props.actions.collectPings,
        config.timings.collectStreamPings,
      ),
    };

    this._collectionBackoff = null;
    this._pingBackoff = null;
  }

  componentDidMount() {
    if (window) {
      window.addEventListener(
        'resize',
        debounce(this.props.actions.windowResize, config.timings.resizeThrottle, {
          trailing: true,
        }),
      );
      window.addEventListener('beforeunload', () => {
        this.props.actions.closeAllParlays({
          actions: this.props.actions,
        });
      });
      window.addEventListener('visibilitychange', Timings.handleVisibilityChange);
      this.props.actions.windowResize();

      if (config.support.tools.betslipStress) {
        window._betslipStress = (count, sport) => {
          betslipStress(this.props.actions, count, sport);
        };
      }
    }

    if (config.support.tools.recordLatency) {
      this._collectionBackoff = setTimeout(() => {
        this.props.actions.recordLatencyStats();
        this.setState({
          _statsInterval: setInterval(
            this.props.actions.recordLatencyStats,
            config.timings.collectDataStreamLatency,
          ),
        });
      }, config.timings.collectBackoff);
    }

    this._pingBackoff = setTimeout(() => {
      this.props.actions.collectPings();
      this.setState({
        _statsInterval: setInterval(
          this.props.actions.collectPings,
          config.timings.collectStreamPings,
        ),
      });
    }, config.timings.pingBackoff);

    //set event pre-watch mode for quicker price popuation
    this.props.actions.setPreWatchMode({
      value: true,
    });

    //set ui version
    this.props.actions.setUIVersion({
      version: pkg.version,
      basename: routes.basename,
      application: pkg.name,
    });

    //check if user is authenticated
    if (this.props.isAuth === 'pending') {
      this.props.actions.isUserAuth({
        actions: this.props.actions,
      });
    }

    //Telemetry.pageview(window.location.pathname);
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('resize', this.props.actions.windowResize);
    }

    if (this._collectionBackoff) {
      clearTimeout(this._collectionBackoff);
    }

    if (this._pingBackoff) {
      clearTimeout(this._pingBackoff);
    }

    if (this.state._statsInterval) {
      clearInterval(this.state._statsInterval);
    }

    if (this.state._pingInterval) {
      clearInterval(this.state._pingInterval);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuth !== prevProps.isAuth && prevProps.isAuth === 'yes') {
      //force-cleanup of things
      this.props.actions.baseStreamDisconnect();
    }

    if (this.props.theme !== prevProps.theme) {
      let ret = {};
      ret.theme = this.props.theme;
      let toSet = {};
      let toSet2 = {};
      ret._theme = createMuiTheme(merge(toSet, sass.themeBase, config.themes[this.props.theme]));
      ret._molly_theme = createTheme(
        merge(toSet2, themes[this.props.theme], {
          primary: config.themes[this.props.theme].palette.common.accent,
        }),
      );
      this.setState(ret);
    }

    if (this.props.isAuth !== prevProps.isAuth && this.props.isAuth === 'yes') {
      let pricesBookies = this.props.pricesBookies ? this.props.pricesBookies.toJS() : [];
      this.props.actions.baseStreamConnect({
        monitorHeartBeats: true,
        recover: true,
        currentUser: this.props.currentUser,
        language: this.props.language,
        bookies: pricesBookies,
        sessionId: this.props.sessionId,
        actions: this.props.actions,
      });
    }
  }

  render() {
    Timings.handleAppRender();

    //auth routes
    let expAuth = extractPaths(routes, (route) => route.needsAuth);
    let routesAuth = expAuth.map((rt, index) =>
      !this.props.isDemo || rt.demo ? (
        <PrivateRoute
          key={index}
          isAuth={this.props.isAuth}
          path={rt.path}
          component={rt.component}
          logout={this.props.actions.logout}
        />
      ) : null,
    );
    //unauth routes
    let expUnAuth = extractPaths(routes, (route) => !route.needsAuth);
    let routesUnAuth = expUnAuth.map((rt, index) =>
      !this.props.isDemo || rt.demo ? (
        <PublicRoute key={index} path={rt.path} component={rt.component} />
      ) : null,
    );

    return (
      <ThemeProvider theme={this.state._molly_theme}>
        <FontStyles />
        <SettingsContext.Provider value={{ ...this.props, _theme: this.state._theme, isAgentsMollybet: this.props.isAgentsMollybet }}>
          <MuiThemeProvider theme={this.state._theme}>
            <IntlProvider locale={this.props.language} messages={messages[this.props.language]}>
              <div id="app">
                <Router basename={routes.basename}>
                  <ViewErrorBoundary>
                    <Switch>
                      {routesAuth}
                      {routesUnAuth}
                      <Route exact path="/" component={() => <Redirect to={routes.default} />} />
                      <Route component={FourOughFour} />
                    </Switch>
                    <PlatformHealthRecorder />
                    <ComponentErrorBoundary>
                      <SearchResultsContainer />
                    </ComponentErrorBoundary>
                  </ViewErrorBoundary>
                </Router>
                {this.props.isAuth === 'yes' && (
                  <>
                    <ComponentErrorBoundary>
                      <SoundManager />
                    </ComponentErrorBoundary>
                    <ComponentErrorBoundary>
                      <BetTypeOptions />
                    </ComponentErrorBoundary>
                    <ComponentErrorBoundary>
                      <PayoutCalc />
                    </ComponentErrorBoundary>
                    <ComponentErrorBoundary>
                      <FeedbackOverlay />
                    </ComponentErrorBoundary>
                    <ComponentErrorBoundary>
                      <Deposit />
                    </ComponentErrorBoundary>
                    {this.props.contentLayout === 'normal' && !this.props.sudoer && (
                      <ComponentErrorBoundary>
                        <Announcements />
                      </ComponentErrorBoundary>
                    )}
                  </>
                )}
              </div>
            </IntlProvider>
          </MuiThemeProvider>
        </SettingsContext.Provider>
      </ThemeProvider>
    );
  }
}

// turn state of combined reducers into state required by component
const mapStateToProps = (state) => {
  let contentLayout = state.getIn(['ui', 'uiSpecs', 'contentLayout'], 'normal');
  let baseSport = state.getIn(['trade', 'baseSport'], '');
  let sport = state.getIn(['trade', 'sport'], '');
  let displayCcy = state.getIn(['base', 'settings', 'general', 'displayCcy'], '').toLowerCase();

  let pricesBookies = state.getIn(['base', 'settings', 'trade', 'pricesBookies'], null);

  let apiSync = state.getIn(['base', 'flags', 'apiSync'], false);
  let waitForPmms = 0;

  let confirmBet;
  if (!apiSync) {
    confirmBet = true;
  } else {
    confirmBet = state.getIn(['base', 'settings', 'trade', 'confirmBet'], true);
  }

  const isDemo =
    !!Whitelabel.demoPlatformLink &&
    (Whitelabel.demoPlatformLink.endsWith('/')
      ? Whitelabel.demoPlatformLink
      : `${Whitelabel.demoPlatformLink}/`) ===
      `//${window.location.host.endsWith('/') ? window.location.host : `${window.location.host}`}/`;

  return {
    //basic
    language: state.getIn(
      ['base', 'settings', 'general', 'language'],
      config.defaults?.general?.language ||
        navigator.language.split('-')[0] ||
        config.defaultLanguage,
    ),
    theme: state.getIn(['base', 'settings', 'general', 'theme'], config.defaultTheme),
    timezone: state.getIn(['base', 'settings', 'general', 'timezone'], 'utc'),
    isAuth: state.getIn(['base', 'isAuth'], 'pending'),

    //connection related
    apiSync: state.getIn(['base', 'flags', 'apiSync'], false),
    pricesSync: state.getIn(['trade', 'flags', 'pricesSync'], false),

    //profile-ish
    xrates: state.getIn(['base', 'xrates'], null),
    placer: state.getIn(['base', 'profile', 'username'], '?'),
    currentUser: state.getIn(['base', 'profile', 'username'], '?'),
    sessionId: state.getIn(['base', 'session', 'sessionId'], '?'),
    name: state.getIn(['base', 'profile', 'name'], '?'),
    sudoer: state.getIn(['base', 'profile', 'sudoer'], ''),
    agent:
      state.getIn(['base', 'profile', 'config', 'agent'], false) ||
      state.getIn(['base', 'profile', 'config', 'subAgent'], false),
    masterAgent: state.getIn(['base', 'profile', 'config', 'masterAgent'], false),
    subAgent: state.getIn(['base', 'profile', 'config', 'subAgent'], false),
    superuser: state.getIn(['base', 'profile', 'config', 'superuser'], false),
    seePt: state.getIn(['base', 'profile', 'config', 'seePt'], false),
    limitedAdmin: state.getIn(['base', 'profile', 'config', 'limitedAdmin'], false),
    netPrices: state.getIn(['base', 'profile', 'config', 'netPrices'], false),
    exchangeMode: state.getIn(['base', 'settings', 'trade', 'exchangeMode'], 'make_and_take'),

    hideCredit: state.getIn(['base', 'settings', 'general', 'hideCredit'], false),
    hideUsername: state.getIn(['base', 'settings', 'general', 'hideUsername'], false),

    //visual
    priceType: state.getIn(['base', 'settings', 'general', 'priceType'], 'dec'),
    contentLayout,
    navLayout: state.getIn(['ui', 'uiSpecs', 'navLayout'], 'normal'),
    navMenuHeight: contentLayout !== 'narrow' ? sizes.navMenuHeight : sizes.navMenuHeightNarrow,
    fontSize: sizes.fontSize,
    userTradeView: state.getIn(['base', 'settings', 'trade', 'userTradeView'], 'default'),
    userTradeViewOrNarrow:
      contentLayout !== 'narrow'
        ? state.getIn(['base', 'settings', 'trade', 'userTradeView'], 'default')
        : contentLayout,
    interfaceWidth: state.getIn(['ui', 'uiSpecs', 'width'], window.innerWidth),
    interfaceHeight: state.getIn(['ui', 'uiSpecs', 'height'], window.innerHeight),
    ccyCode: state.getIn(['base', 'profile', 'ccyCode'], '?').toLowerCase(),
    displayCcy: displayCcy || state.getIn(['base', 'profile', 'ccyCode'], '').toLowerCase(),
    spyOnGroup: state.getIn(['base', 'profile', 'config', 'spyOnGroup'], false),
    dockedDraggables: contentLayout === 'narrow',
    betslipTint: state.getIn(['base', 'settings', 'trade', 'betslipTint'], false),

    sport,
    baseSport,
    enabledGroups: state.getIn(['base', 'settings', 'trade', 'enabledGroups', baseSport], null),
    allEnabledGroups: state.getIn(['base', 'settings', 'trade', 'enabledGroups'], null),
    hideExchangeOnlyLines: state.getIn(
      ['base', 'settings', 'trade', 'hideExchangeOnlyLines'],
      true,
    ),
    showAgentsInHistory: state.getIn(['base', 'profile', 'config', 'showAgentsInHistory'], false),
    seeAccounts: state.getIn(['base', 'profile', 'config', 'seeAccounts'], false),
    orderLogCategories: state.getIn(['base', 'profile', 'preferences', 'orderLogCategories'], null),
    tradeTint: state.getIn(['base', 'settings', 'trade', 'tradeTint'], false),
    hideFavsCompetitionsHeaders: state.getIn(
      ['base', 'settings', 'trade', 'hideFavsCompetitionsHeaders'],
      false,
    ),
    sideOpen: state.getIn(['ui', 'settings', 'trade', 'side', 'open'], true),

    //betslip
    equivalentBets: state.getIn(['base', 'settings', 'trade', 'equivalentBets'], true),
    bookieMinBalances: state.getIn(['base', 'settings', 'trade', 'bookieMinBalances'], null),
    multipleAccounts:
      state.getIn(['base', 'profile', 'config', 'canUseMultipleAccounts'], false) &&
      state.getIn(['base', 'settings', 'trade', 'multipleAccounts'], false),
    waitForPmms: Math.max(
      waitForPmms,
      state.getIn(['base', 'settings', 'trade', 'waitForPmms'], 0),
    ),
    parlayMode: state.getIn(['ui', 'parlayMode'], false),
    parlaySportRestrictions: state.getIn(['trade', 'parlaySportRestrictions'], null), //not used really
    maxBetslips: state.getIn(['base', 'profile', 'config', 'maxBetslips'], 8),
    multipleHandicapLines: state.getIn(
      ['base', 'settings', 'trade', 'multipleHandicapLines'],
      false,
    ),
    autoAddBetsToFavs: state.getIn(['base', 'settings', 'trade', 'autoAddBetsToFavs'], false),
    allTermsAccepted: state.getIn(['base', 'flags', 'allTermsAccepted'], true),
    advancedOrderPlacementOptions: state.getIn(
      ['base', 'settings', 'trade', 'advancedOrderPlacementOptions'],
      false,
    ),
    reselectAccounts: state.getIn(['base', 'settings', 'trade', 'reselectAccounts'], 'always'),
    betslipDefaultTimeout: state.getIn(['base', 'settings', 'trade', 'betslipDefaultTimeout'], 20),
    defaultPrice: state.getIn(['base', 'settings', 'trade', 'defaultPrice'], ''),
    defaultStake: state.getIn(['base', 'settings', 'trade', 'defaultStake'], ''),
    webPlaceBets: state.getIn(['base', 'profile', 'config', 'webPlaceBets'], false),
    maxOrder: state.getIn(['base', 'settings', 'trade', 'maxOrder'], null),
    minOrder: state.getIn(['base', 'settings', 'trade', 'minOrder'], 0),
    confirmBet,
    autofillMiddleHandicaps: state.getIn(
      ['base', 'settings', 'trade', 'autofillMiddleHandicaps'],
      false,
    ),
    extraEdgeHandicaps: state.getIn(['base', 'settings', 'trade', 'extraEdgeHandicaps'], false),
    alwaysExpandAllMarkets: state.getIn(
      ['base', 'settings', 'trade', 'alwaysExpandAllMarkets'],
      false,
    ),

    showOverround:
      state.getIn(['base', 'settings', 'trade', 'showOverround'], false) &&
      contentLayout !== 'narrow',

    openAnySelection: state.getIn(['base', 'settings', 'trade', 'openAnySelection'], false),
    showAllScores: state.getIn(['base', 'settings', 'trade', 'showAllScores'], false),
    validTradeBookies: state.getIn(['base', 'validTradeBookies'], null),
    validPricesBookies: state.getIn(['base', 'validPricesBookies'], null),

    advancedBetslipInfo: state.getIn(['base', 'settings', 'trade', 'advancedBetslipInfo'], true),

    betslipAgent: state.getIn(['base', 'settings', 'trade', 'betslipAgent'], false),
    bookieTint: state.getIn(['base', 'settings', 'trade', 'bookieTint'], false),
    useSuggested: state.getIn(['base', 'settings', 'trade', 'useSuggested'], true),
    showBrokerInfoText: state.getIn(['base', 'settings', 'trade', 'showBrokerInfoText'], false),
    valuesInPositionButton: state.getIn(
      ['base', 'settings', 'trade', 'valuesInPositionButton'],
      false,
    ),
    pricesBookies,
    //because unticked bookies shouldn't matter here
    disabledBookies:
      state.getIn(['base', 'settings', 'trade', 'reselectAccounts'], 'always') !== 'always'
        ? state.getIn(['base', 'settings', 'trade', 'disabledBookies'], null)
        : null,
    sortEventsBy: state.getIn(['base', 'settings', 'trade', 'sortEventsBy'], 'comp'),

    //agent limitations
    canCreateUsers: state.getIn(['base', 'profile', 'config', 'canCreateUsers'], false),
    canUseCasino: state.getIn(['base', 'profile', 'config', 'canUseCasino'], false),
    casinoUser: state.getIn(['base', 'profile', 'config', 'casinoUser'], false),
    canCreateTransfers: state.getIn(['base', 'profile', 'config', 'canCreateTransfers'], false),
    canUseMultipleAccounts: state.getIn(
      ['base', 'profile', 'config', 'canUseMultipleAccounts'],
      false,
    ),
    canChangeCreditLimits: state.getIn(
      ['base', 'profile', 'config', 'canChangeCreditLimits'],
      false,
    ),
    canChangeNetPrices: state.getIn(['base', 'profile', 'config', 'canChangeNetPrices'], false),

    //features
    agentPositionGrids:
      (state.getIn(['base', 'profile', 'config', 'spyOnGroup'], false) ||
        state.getIn(['base', 'profile', 'config', 'agent'], false)) &&
      state.getIn(['base', 'settings', 'trade', 'agentPositionGrids'], false),

    featureShowInternalInfo:
      state.getIn(['base', 'profile', 'config', 'superuser'], false) ||
      state.getIn(['base', 'profile', 'config', 'limitedAdmin'], false),
    featureAdvancedTradeSettings: state.getIn(['base', 'switches', 'advancedTradeSettings'], false),

    featureNetPositionGrids: state.getIn(['base', 'switches', 'netPositionGrids'], false),
    featureOverround: state.getIn(['base', 'switches', 'overround'], false),
    featureDepositButton: state.getIn(['base', 'switches', 'depositButton'], false),
    featureCupidPricePreview: state.getIn(['base', 'switches', 'cupidPricePreview'], false),
    featureLognoice: state.getIn(['base', 'switches', 'lognoice'], false),
    featureEquivalentBetsOption: state.getIn(['base', 'switches', 'equivalentBetsOption'], false),
    featureFlatten: state.getIn(['base', 'switches', 'flatten'], false),
    featureMultirunner: state.getIn(['base', 'switches', 'multirunner'], false),
    featureTranslatedBetTypes: state.getIn(['base', 'switches', 'translatedBetTypes'], false),
    featureParlay: state.getIn(['base', 'switches', 'parlay'], false),
    featureForex: state.getIn(['base', 'switches', 'forex'], false),
    featureSmartCredit: state.getIn(['base', 'switches', 'smartCredit'], false),
    featureHorse: state.getIn(['base', 'switches', 'horse'], false),
    featureSonic: state.getIn(['base', 'switches', 'sonic'], false), // show sonic beta button
    //parlay id
    parlayId: state.getIn(['parlays', 'parlays']).keySeq().first(),
    //demo mode
    isDemo,
    // if the url is agents.mollybet.com
    isAgentsMollybet: window.location.href.includes('agents.mollybet.com')
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return (
      prev.getIn(['base'], null) === next.getIn(['base'], null) &&
      prev.getIn(['trade', 'sport'], null) === next.getIn(['trade', 'sport'], null) &&
      prev.getIn(['trade', 'baseSport'], null) === next.getIn(['trade', 'baseSport'], null) &&
      prev.getIn(['ui', 'parlayMode'], false) === next.getIn(['ui', 'parlayMode'], false) &&
      prev.getIn(['parlays', 'parlays']).keySeq().first() ===
        next.getIn(['parlays', 'parlays']).keySeq().first()
    );
  },
})(App);
